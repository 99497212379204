export const HOMEPAGE = import.meta.env.REACT_APP_HOMEPAGE_URL;
export const HELP_CENTER = "https://help.driven.world";
export const TERMS_CONDITIONS =
  "https://www.iubenda.com/terms-and-conditions/14763814";
export const PRIVACY_POLICY = "https://www.iubenda.com/privacy-policy/14763814";
export const COOKIE_POLICY =
  "https://www.iubenda.com/privacy-policy/14763814/cookie-policy";

export const ABOUT = `${HOMEPAGE}/about`;
export const BLOG = `${HOMEPAGE}/blog`;
export const CAREERS = `${HOMEPAGE}/careers`;
export const RELEASES = `${HOMEPAGE}/releases`;

export const ACTIVITY = "/activity";
export const ADD = "/add";
export const ADMIN = "/admin";
export const BILLING = "/billing";
export const COLLECTION = "/collection";
export const COLLECTOR = "/collector";
export const CONFIRM_CREW_MEMBERSHIP = "/confirmCrewMembership";
export const CONFIRM_EMAIL = "/confirmEmail";
export const CONFIRM_MFA = "/confirmMfa";
export const DASHBOARD = "/dashboard";
export const DATA_ROOM = "/data-room";
export const DEALERSHIP = "/dealership";
export const DOCUMENT = "/document";
export const EARLY_ACCESS = "/early-access";
export const GALLERY = "/gallery";
export const GARAGE = "/garage";
export const LOCATION = "/location";
export const LOGBOOK = "/logbook";
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const TRANSACTIONS = "/transactions";
export const MY_LISTINGS = "/data-rooms";
export const NEGOTIATION = "/discussion";
export const NEGOTIATIONS = "/discussions";
export const ORGANIZATION = "/organization";
export const REMINDERS = "/reminder";
export const REQUEST = "/request";
export const RESET_PASSWORD = "/resetPassword";
export const SERVICE = "/service";
export const SETTINGS = "/settings";
export const SUBSCRIBE = "/subscribe";
export const SUMMARY = "/summary";
export const USAGE_HISTORY = "/usage-history";
export const USER_MANAGEMENT = "/user-management";
export const VAULT = "/vault";
export const VEHICLE = "/vehicle";
export const VIEW = "/view";
export const WISHLIST = "/wishlist";
export const TRANSACTIONS_NEGOTIATIONS = `${TRANSACTIONS}${NEGOTIATIONS}`;
export const TRANSACTIONS_MY_DATA_ROOMS = `${TRANSACTIONS}${DATA_ROOM}`;

export const ORGANIZATION_COLLECTOR = `${ORGANIZATION}${COLLECTOR}`;
export const ORGANIZATION_DATA_ROOM = `${ORGANIZATION}${DATA_ROOM}`;
export const ORGANIZATION_DEALERSHIP = `${ORGANIZATION}${DEALERSHIP}`;
export const ORGANIZATION_LOCATION = `${ORGANIZATION}${LOCATION}`;
export const ORGANIZATION_USER_MANAGEMENT = `${ORGANIZATION}${USER_MANAGEMENT}`;
export const ORGANIZATION_REQUEST = `${ORGANIZATION}${REQUEST}`;
export const ORGANIZATION_SERVICE = `${ORGANIZATION}${SERVICE}`;
export const ORGANIZATION_USAGE_HISTORY = `${ORGANIZATION}${USAGE_HISTORY}`;
export const ORGANIZATION_VEHICLE = `${ORGANIZATION}${VEHICLE}`;

export const SIGNUP = "/signUp";

export const ONBOARDING = `/onboarding`;

export const ADMIN_LAYOUT_SCOPES = `${ADMIN}/layout-scopes`;
export const ADMIN_CONCIERGES = "/admin/concierges";
export const ADMIN_VEHICLES = "/admin/vehicles";
export const ADMIN_USAGE_HISTORY = `${ADMIN}${USAGE_HISTORY}`;
export const ADMIN_USERS = "/admin/users";
export const ADMIN_LISTINGS = `${ADMIN}/listings`;

export const SETTINGS_COLLABORATION = `${SETTINGS}/collaboration`;
export const SETTINGS_EXPERIMENTS = `${SETTINGS}/experiments`;
export const SETTINGS_PROFILE = `${SETTINGS}/profile`;
export const SETTINGS_SECURITY = `${SETTINGS}/security`;
export const SETTINGS_SUBSCRIPTION = `${SETTINGS}/subscription`;
export const SETTINGS_SUBSCRIPTION_MEMBERSHIP = `${SETTINGS_SUBSCRIPTION}/membership`;
export const SETTINGS_SUBSCRIPTION_INVOICES = `${SETTINGS_SUBSCRIPTION}/invoices`;
export const SETTINGS_SUBSCRIPTION_PAYMENT_METHODS = `${SETTINGS_SUBSCRIPTION}/payment-methods`;
export const SETTINGS_TAG_MANAGER = `${SETTINGS}/tag-manager`;

export const SUBSCRIBE_BILLING = `${SUBSCRIBE}${BILLING}`;
export const SUBSCRIBE_SUMMARY = `${SUBSCRIBE}${SUMMARY}`;

export const GARAGE_SUBSCRIBE = `${GARAGE}${SUBSCRIBE}`;
export const GARAGE_SUBSCRIBE_BILLING = `${GARAGE_SUBSCRIBE}${BILLING}`;
export const GARAGE_SUBSCRIBE_SUMMARY = `${GARAGE_SUBSCRIBE}${SUMMARY}`;

export const MAINTENANCE = "/maintenance";

export const MAILTO_SUPPORT = "mailto:support@driven.world";
