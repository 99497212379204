import { lazy } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";

import { Layout } from "components/Layout/Layout";
import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import {
  CONFIRM_EMAIL,
  CONFIRM_MFA,
  EARLY_ACCESS,
  LOGIN,
  RESET_PASSWORD,
  SIGNUP,
  VIEW,
} from "constants/routes";
import { useLoggedInRootPath } from "hooks/useLoggedInRootPath";

const BIPage = lazy(() => import("pages/BI/BIPage"));
const ChangePasswordPage = lazy(
  () => import("pages/ChangePassword/ChangePasswordPage"),
);
const ConfirmEmailPage = lazy(
  () => import("pages/ConfirmEmail/ConfirmEmailPage"),
);
const ConfirmMfaPage = lazy(() => import("pages/ConfirmMfa/ConfirmMfaPage"));
const KesselPage = lazy(() => import("pages/Kessel/KesselPage"));
// const ListingPage = lazy(
//   () => import("pages/data-room/dataRoomId/PublicDataRoomPage"),
// );
const LoginPage = lazy(() => import("pages/Login/LoginPage"));
const NotFoundPage = lazy(() => import("pages/NotFound/NotFoundPage"));
const ResetPasswordPage = lazy(
  () => import("pages/ResetPassword/ResetPasswordPage"),
);
const SignUpPage = lazy(() => import("pages/Signup/SignUpPage"));
const ViewPage = lazy(() => import("pages/View/ViewPage"));
const PublicAppBar = lazy(() => import("components/AppBar/PublicAppBar"));
const UnsubscribePage = lazy(() => import("pages/unsubscribe/UnsubscribePage"));

const ContainedLayout = () => (
  <Layout onlyHorizontalAppBar AppBar={PublicAppBar}>
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  </Layout>
);

const NarrowCenteredLayout = () => (
  <Layout
    maxWidth="xs"
    sx={{ padding: 2, alignItems: "center", display: "flex", height: "100%" }}
  >
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  </Layout>
);

const Root = () => {
  const loggedInRootPath = useLoggedInRootPath();

  return <Navigate to={loggedInRootPath} />;
};

const narrowCenteredTopRoutes = [
  <Route key={SIGNUP} path={SIGNUP}>
    <Route index element={<SignUpPage />} />
  </Route>,

  <Route
    key={RESET_PASSWORD}
    element={<ResetPasswordPage />}
    path={RESET_PASSWORD}
  />,

  <Route
    key={`${RESET_PASSWORD}/:token`}
    element={<ChangePasswordPage />}
    path={`${RESET_PASSWORD}/:token`}
  />,

  <Route
    key={`${CONFIRM_EMAIL}/:token`}
    element={<ConfirmEmailPage />}
    path={`${CONFIRM_EMAIL}/:token`}
  />,

  <Route key={LOGIN} element={<LoginPage />} path={LOGIN} />,

  <Route key={CONFIRM_MFA} element={<ConfirmMfaPage />} path={CONFIRM_MFA} />,

  <Route key="bi" element={<BIPage />} path="/bi" />,

  <Route key="kessel" element={<KesselPage />} path="/kessel" />,

  <Route key="unsubscribe" element={<UnsubscribePage />} path="/unsubscribe" />,
];

const containedTopRoutes = [
  // <Route
  //   key={DATA_ROOM}
  //   element={<ListingPage />}
  //   path={`${DATA_ROOM}/:dataRoomId`}
  // />,
  <Route key={VIEW} element={<ViewPage />} path="/view/:token" />,
];

const routes = [
  <Route
    key={EARLY_ACCESS}
    element={<Navigate to={SIGNUP} />}
    path={EARLY_ACCESS}
  />,

  <Route key="contained" element={<ContainedLayout />}>
    {containedTopRoutes}
  </Route>,

  <Route key="contained" element={<NarrowCenteredLayout />}>
    {narrowCenteredTopRoutes}
  </Route>,

  <Route key="root" element={<Root />} path="/" />,

  <Route key="notFound" element={<ContainedLayout />}>
    <Route element={<NotFoundPage />} path="/*" />
  </Route>,
];

export function PublicRoutes() {
  return <>{routes.map((route) => route)}</>;
}
