import { lazy, useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
  useRouteError,
  useSearchParams,
} from "react-router-dom";
import { wrapCreateBrowserRouter } from "@sentry/react";

import { DelayedWrapper } from "components/Layout/DelayedWrapper";
import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { MaintenanceGuard } from "components/MaintenanceGuard/MaintenanceGuard";
import {
  LOGOUT,
  // TODO: transactions are temporarily disabled
  // TRANSACTIONS,
} from "constants/routes";
import { AuthenticatedUserContextProvider } from "contexts/useAuthenticatedUserContext";
import { useAppThemeQueryParam } from "hooks/useAppThemeQueryParam";
import { AuthenticatedRoutes } from "pages/AuthenticatedRoutes";
import ErrorPage from "pages/ErrorPage";
import MaintenanceRoutes from "pages/Maintenance/MaintenanceRoutes";
import { PublicRoutes } from "pages/PublicRoutes";
import { reload, TIMESTAMP_QUERY_PARAM } from "utils/window";

const LogoutPage = lazy(() => import("pages/Logout/LogoutPage"));

const ErrorBoundary = () => {
  const [searchParams] = useSearchParams();
  const timestamp = searchParams.get(TIMESTAMP_QUERY_PARAM);
  const error = useRouteError();

  useEffect(() => {
    const alreadyReloaded = timestamp;
    const isTypeError = error instanceof Error && error.name === "TypeError";

    if (!alreadyReloaded && isTypeError) {
      reload({ force: true });
    }
  }, [error, timestamp]);

  return (
    <DelayedWrapper>
      <ErrorPage error={error} />
    </DelayedWrapper>
  );
};

const Root = () => {
  useAppThemeQueryParam();

  return (
    <AuthenticatedUserContextProvider>
      <ScrollRestoration />

      <SuspenseWrapper>
        <Outlet />
      </SuspenseWrapper>
    </AuthenticatedUserContextProvider>
  );
};

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} errorElement={<ErrorBoundary />} path="/">
      <Route key={LOGOUT} element={<LogoutPage />} path={LOGOUT} />

      {MaintenanceRoutes()}

      <Route element={<MaintenanceGuard />}>
        {AuthenticatedRoutes()}

        {/* PublicRoutes has a catch all, so it goes last */}
        {PublicRoutes()}
      </Route>
    </Route>,
  ),
);

export const AppRoutes = () => <RouterProvider router={router} />;
