import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { Collector } from "typings/Collector";
import {
  DataRoom,
  DataRoomGroup,
  DataRoomGroupMembership,
} from "typings/DataRoom";
import { Media } from "typings/Media";
import {
  DataRoomGroupTopics,
  DataRoomTopics,
  DialogTopics,
} from "typings/Topics";
import { Vehicle } from "typings/Vehicle";

const DataRoomModal = lazy(
  () => import("components/DataRoom/dialogs/DataRoomModal"),
);
const DataRoomGroupModal = lazy(
  () => import("components/DataRoom/dialogs/DataRoomGroupModal"),
);
const DataRoomGroupMembershipModal = lazy(
  () => import("components/DataRoom/dialogs/DataRoomGroupMembershipModal"),
);
const DataRoomAddGroupMembershipModal = lazy(
  () => import("components/DataRoom/dialogs/DataRoomAddGroupMembershipModal"),
);
const DataRoomMediaActivityModal = lazy(
  () => import("components/DataRoom/dialogs/DataRoomMediaActivityModal"),
);

const DataRoomDialogs = () => {
  const { object: toEdit, handleClose } = useModalWithSubscription<{
    dataRoom: DataRoom;
    vehicle: Vehicle;
    ownerId: string;
  }>(DialogTopics.EditDataRoom, DataRoomTopics.Updated);

  const { object: toEditGroup, handleClose: handleCloseGroup } =
    useModalWithSubscription<{
      dataRoom: DataRoom;
      dataRoomGroup: DataRoomGroup;
    }>(DialogTopics.EditDataRoomGroup, DataRoomGroupTopics.Updated);

  const { object: membershipToAdd, handleClose: handleCloseAdd } =
    useModalWithSubscription<{
      dataRoom: DataRoom;
      group: DataRoomGroup;
    }>(
      DialogTopics.DataRoomAddGroupMembership,
      DialogTopics.ViewDataRoomMembership,
    );

  const { object: membershipToView, handleClose: handleCloseView } =
    useModalWithSubscription<DataRoomGroupMembership>(
      DialogTopics.ViewDataRoomMembership,
    );

  const { object: toViewActivity, handleClose: handleCloseViewActivity } =
    useModalWithSubscription<{
      dataRoomId: DataRoom["id"];
      mediaId: Media["id"];
      collectorId: Collector["id"];
    }>(DialogTopics.ViewDataRoomMediaActivity);

  return (
    <>
      {toEdit && (
        <SuspenseWrapper>
          <DataRoomModal {...toEdit} onClose={handleClose} />
        </SuspenseWrapper>
      )}

      {toEditGroup && (
        <SuspenseWrapper>
          <DataRoomGroupModal {...toEditGroup} onClose={handleCloseGroup} />
        </SuspenseWrapper>
      )}

      {membershipToAdd && (
        <SuspenseWrapper>
          <DataRoomAddGroupMembershipModal
            {...membershipToAdd}
            onClose={handleCloseAdd}
          />
        </SuspenseWrapper>
      )}

      {membershipToView && (
        <SuspenseWrapper>
          <DataRoomGroupMembershipModal
            membership={membershipToView}
            onClose={handleCloseView}
          />
        </SuspenseWrapper>
      )}

      {toViewActivity && (
        <SuspenseWrapper>
          <DataRoomMediaActivityModal
            {...toViewActivity}
            onClose={handleCloseViewActivity}
          />
        </SuspenseWrapper>
      )}
    </>
  );
};

export default DataRoomDialogs;
